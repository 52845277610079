
export const environment = {
  production: false,
  appVersion: require('./../../package.json').version+'--dev',
  base: 'area',
  refreshTokenApiUrl : 'https://oauth2-auth-server-oauthjwt-uat-twdwtabx5q-el.a.run.app',
  dashboardApiUrl : 'https://user-management-oauth-uat-twdwtabx5q-el.a.run.app',
  walletBalanceApiUrl : 'https://fetchwalletbalance-twdwtabx5q-uc.a.run.app',
  userFeatureApiUrl : 'https://apidev.iserveu.online',
  userKycStatusApiUrl : 'https://apidev.iserveu.online',
  OnboardingApiBaseUrl: "https://apidev.iserveu.online/commononboarding-ippb",
  OnboardingOtpApibaseUrl:"https://otpservice.iserveu.website",
  baseHypervergeUrl:"https://apidev.iserveu.online/hyperverge-ocr-ippb/gettoken?expiry=",
  elServiceBaseUrl : 'https://127.0.0.1:14000/easyLinkSdkManager',
  matmApiBaseUrl : 'https://apidev.iserveu.online/mATM_Composer',
  matmIPPBApiBaseUrl : 'https://indiapost-matm.iserveu.tech/mATM_Composer',
  userManagementApiUrl : 'https://user-registration-uat-stag.iserveu.tech',
  mATMReportApiUrl : 'https://indmatmtxnreport-zwqcqy3qmq-uc.a.run.app',
  walletReportApiUrl : 'https://indmatmtxnreport-zwqcqy3qmq-uc.a.run.app',
  commissionReportApiUrl : 'https://indmatmtxnreport-zwqcqy3qmq-uc.a.run.app'
};
