import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AppService } from '../app.service';
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private appService: AppService, private cookieService: CookieService ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean { 
   
    if (sessionStorage.getItem('access_token')) {
      return true;
    }
    this.appService.logOut();
    return false;
  }
}
