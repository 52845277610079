import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ErrorComponent } from './demo/error/error.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthGuard } from './guards/auth.guard'
import { DepositslipComponent } from './depositslip/depositslip.component';
import { PaymentgatewaystatusComponent } from './demo/wallettopup/wallettopuprequest/paymentgateway/paymentgatewaystatus/paymentgatewaystatus.component';
// import { OperatorComponent } from './demo/operator/operator.component';

const routes: Routes = [
  {
    path: 'v1',
    component: AdminComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard/analytics',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./demo/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'fundtransfer',
        // canActivate: [AuthGuard],
        // loadChildren: () => import('./demo/reports/reports.module').then(module => module.ReportModule)
        loadChildren: () => import('./demo/fundtransfer/fundtransfer.module').then(module => module.FundtransferModule)
      },
      {
        path: 'reports',
        // canActivate: [AuthGuard],
        // loadChildren: () => import('./demo/reports/reports.module').then(module => module.ReportModule)
        loadChildren: () => import('./demo/reports2/reports.module').then(module => module.ReportsModule)
      },
      {
        path:'pos',
        loadChildren:() => import('./demo/pos-report/pos-report.module').then(module => module.PosReportModule)
      },
      {
        path:'matm',
        loadChildren:() => import('./demo/reports2/newmatm/newmatm/newmatm.module').then(module => module.NewmatmModule)
      },
      {
        path:'wallet',
        loadChildren:() => import('./demo/reports2/wallet/wallet/wallet.module').then(module => module.WalletModule)
      },
      {
        path:'commission',
        loadChildren:() => import('./demo/reports2/commission/commission/commission.module').then(module => module.CommissionModule)
      },
      {
        path: 'cashout',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./demo/cashout/cashout.module').then(module => module.CashoutModule)
      },
      // {
      //   path: 'godigit',
      //   // canActivate: [AuthGuard],
      //   loadChildren: () => import('./demo/insurance/insurance.module').then(module => module.InsuranceModule)
      // },
      {
        path: 'payment',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./demo/payment/payment.module').then(module => module.PaymentModule)
      },
      {
        path: 'wallettopup',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./demo/wallettopup/wallettopup.module').then(module => module.WallettopupModule)
      },
      {
        path: 'operator',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/operator/operator.module').then(module => module.OperatorModule)
      },
      // {
      //   path: 'insurance',
      //   canActivate: [AuthGuard],
      //   loadChildren: () => import('./insurance/insurance.module').then(module => module.InsuranceModule)
      // },
      {
        path: 'commission',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/commission/commission.module').then(module => module.CommissionModule)
      },
      // {
      //   path: 'raiseticket',
      //   canActivate: [AuthGuard],
      //   loadChildren: () => import('./demo/raiseticket/raiseticket.module').then(module => module.RaiseticketModule)
      // },
      {
        path: 'Kyc',
        canActivate: [AuthGuard],
        loadChildren: () => import('./kyc/kyc.module').then(module => module.KycModule)
      },
      {
        path: 'newmatm',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/newmatm/newmatm.module').then(module => module.NewmatmModule)
      },
      {
        path: 'talktous',
        canActivate: [AuthGuard],
        loadChildren: () => import('./talk-to-us/talk-to-us.module').then(module => module.TalkToUsModule)
      },
      {
        path: 'support',
        canActivate: [AuthGuard],
        loadChildren: () => import('./helpsupport/helpsupport.module').then(module => module.HelpsupportModule)
      },
      {
        path: 'myprofile',
        canActivate: [AuthGuard],
        loadChildren: () => import('./myprofile/myprofile.module').then(module => module.MyprofileModule)
      }, 
    ]
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule)
  },
  {
    path: 'depositslip',
    component: DepositslipComponent
  },
  {
    path: 'paymentGatewayStatus',
    component: PaymentgatewaystatusComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    component: ErrorComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
