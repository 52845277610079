import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, fromEvent, Subject } from "rxjs";

import { StorageMap } from "@ngx-pwa/local-storage";
import jwt_decode from 'jwt-decode';
import * as vex from 'vex-js';
import { finalize } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { Socket2Service } from "./socket2.service";
import { AuthConfig } from "./app-config";
import { AuthApi } from "./auth/auth.api";
import { CookieService } from "ngx-cookie-service";
import { exit } from "process";
import { start } from "repl";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AppService {

  walletBalance = new BehaviorSubject(0);
  wallet2Balance = new BehaviorSubject(0);
  userDynamicFeature = new BehaviorSubject([]);
  logOutTimer: any = undefined;
  token: string;
  constructor(
    private router: Router,
    private storage: StorageMap,
    private http: HttpClient,
    private ngxSpinner: NgxSpinnerService,
    private socket2Service: Socket2Service,
    private cookieService: CookieService
  ) { }

  autoLogOut() { 
    vex.closeAll(); // Close all vex dialogs
    const tokenData: any = sessionStorage.getItem('exp');
    const startDate = new Date(); 
    const exp_date = new Date(startDate.getTime()+(tokenData/60)*60000);
    sessionStorage.getItem('expDate')?sessionStorage.getItem('expDate'): sessionStorage.setItem('expDate', exp_date.toString());
    const expDate = new Date(sessionStorage.getItem('expDate'));
    const session = Math.ceil((<any>expDate - <any>startDate));
    // Clear the Previous timeout before instatntiating another. Useful for preventing multiple instances of Timeout.
    if (this.logOutTimer) {
      clearTimeout(this.logOutTimer);
    }
    this.logOutTimer = setTimeout(() => {
      window.removeEventListener('offline', this.handleOffline, true);
      console.clear(); // Clear the Console.
      sessionStorage.clear();
      this.storage.clear().subscribe(() => { });
      this.logOut();
    }, session);
  }

  async Gotodashboard(){
    var url = window.location.href;
    console.log(url);
    var urlArr = url.split("/");
    var urlArrLen = urlArr.length;
    var params = urlArr[urlArrLen-1].split('&');
    if(!sessionStorage.getItem('expDate')){
      if(params.length>0){
      sessionStorage.setItem('access_token', atob(params[0]));
      sessionStorage.setItem('refresh_token', atob(params[1]));
      sessionStorage.setItem('exp', atob(params[2]));
      sessionStorage.setItem('logouturl', atob(params[3]));
      this.cookieService.set('logouturl', atob(params[3]));
      }
    }else{
      // const decToken: any = sessionStorage.getItem('exp'); 
      // const start_date = new Date();
      // sessionStorage.getItem('expDate')?sessionStorage.getItem('expDate'): sessionStorage.setItem('expDate', start_date.toString());
      // const startDate = new Date(sessionStorage.getItem('startDate')); 
      // const expDate = new Date(startDate.getTime()+(decToken/60)*60000);
      // if(startDate > expDate){
        this.autoLogOut();
        // } 
      }
  }
  logOut() {
    window.removeEventListener('offline', this.handleOffline, true); 
    console.clear(); // Clear the Console.
    vex.closeAll(); // Close all vex dialogs
    sessionStorage.clear();
    this.storage.clear().subscribe(() => { });
    const url = "http://"+this.cookieService.get('logouturl')+"/#/sign-in";
    console.log(url);    
    window.location.replace(url);
    // window.location.replace("http://localhost:4569/#/sign-in");
    // this.router.navigate(['/']);
  }

  fetchWalletBalance() {
  //   const tokenData: {user_name: string} = jwt_decode(sessionStorage.getItem('access_token'));
  //  //  this.http.post('https://grpcwallet-vn3k2k7q7q-uc.a.run.app/wallet/checkbalance', {user_name: tokenData.sub}) // Staging
  //   // this.http.post('https://grpcwalletprod-vn3k2k7q7q-uc.a.run.app/wallet/checkbalance', {user_name: tokenData.sub}) // Production
  //   this.http.post('https://grpcwalletprod.iserveu.tech/wallet/checkbalance', {user_name: tokenData.user_name}) // Production
  //   .pipe(finalize(() => { this.ngxSpinner.hide('walletSpinner'); }))
  //   .subscribe(
  //     (res: any) => {
  //       // console.log('Wallet Balance Response: ', res);
  //       this.walletBalance.next(res.response.Balance); // Update Wallet Balance.
  //     },
  //     (err: any) => {
  //       console.log('Wallet Balance Error: ', err);
  //       // vex.dialog.alert(err.error.errorMessage);
  //       this.walletBalance.next(0);
  //     }
  //   );
  }

  async fetchWallet2Blanace() {
    // let encUrl = await AuthConfig.config.encodeUrl(AuthApi.url.wallet2)
    let url = environment.walletBalanceApiUrl+'/wallet/wallet2fetchbalance';
    this.http.get(url)
    .pipe(finalize(() => { this.ngxSpinner.hide('wallet2Spinner'); }))
    .subscribe(
      (res: any) => {
        console.log('Wallet 2 Balance Response: ', res);
        this.wallet2Balance.next((res.data.balance).toFixed());
      },
      (err: any) => {
        console.log('Wallet 2 Balance Error: ', err);
        this.wallet2Balance.next(0);
      }
    )
  }

  fetchNotifications(notiReqBody: any) {
    return this.http.post('https://inappnotification.iserveu.tech/fetch_notification', notiReqBody); //Live
    // return this.http.post('https://mongodb-sync-twdwtabx5q-uc.a.run.app/fetch_notification', notiReqBody); //Staging
  }
  fetchNotificationsPromotional(notiReqBody: any) {
    return this.http.post('https://inappnotification.iserveu.tech/fetch_promotional_notification', notiReqBody); //Live
    // return this.http.post('https://mongodb-sync-twdwtabx5q-uc.a.run.app/fetch_notification', notiReqBody); //Staging
  }

  fetchDynamicFeature(){
    const tokenData: {user_name: string} = jwt_decode(sessionStorage.getItem('access_token'));
    this.http.post(environment.userFeatureApiUrl+'/ippb/fetch_user_feature', {user_name: tokenData.user_name}) 
    .pipe(finalize(() => {}))
    .subscribe(
      (res: any) => {
        // console.log(res);debugger;
        this.userDynamicFeature.next(res.userFeature);
        sessionStorage.setItem('dynmcusrfture', btoa(JSON.stringify(res.userFeature)));
        // this.storage.set('Dynamicfeature', res).subscribe(() => { });
      },
      (err: any) => {
        console.log('Dynamic feature API Error: ', err);
        this.userDynamicFeature.next([]);
      }
    );
  }
  
  observeInternetConn() {
    console.log('Checking Internet Offline Status');    
    window.addEventListener('offline', this.handleOffline, true);
  }

  handleOffline = (evt) => {
    console.log('Internet Disconnected.')
    // console.log('Internet Disconnected Event: ', evt);
    // Disconnect Socket, if internet disconnected
    if (this.socket2Service.isConnected) {
      this.socket2Service.disconnectSocket();
    }
    // vex.dialog.alert('Internet Disconnected');
  }
  send_otp(api, Data: any) {
    return this.http.post(api, Data);
  }
  change_password(api, Data: any) {
    return this.http.post(api, Data);
  }

  Myprofile() {
    this.router.navigate(['/v1/myprofile']);
  //   this.token = sessionStorage.getItem('access_token');
  //   // console.log(this.token)
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': this.token

  //     })
  //   };
  //   // console.log(httpOptions);
  //   return this.http.get("https://9fin.co.in//user/getuserprofile.json?id=", httpOptions)
  }
}
