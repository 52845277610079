import { environment } from "src/environments/environment";
export class AuthApi {
    public static url = {
        login: 'https://coreuat-zwqcqy3qmq-el.a.run.app/getlogintoken', 
        dashboard: 'https://coreuat-zwqcqy3qmq-el.a.run.app/user/dashboard',         
        // wallet2: 'https://itpl.iserveu.tech/user/wallet2/getuserbalance', // LIVE API
        wallet2: 'https://core-app-v2-uat-twdwtabx5q-el.a.run.app/user/wallet2/getuserbalance', // staging api
        wallet1: 'https://core-app-v2-uat-twdwtabx5q-el.a.run.app/user/wallet1/getuserbalance', // Staging API
        // wallet1: 'https://itpl.iserveu.tech/user/getuserbalance.json',
        refreshToken: 'https://itpl.iserveu.tech/logintokenrefresh.json',
        send_otp: 'https://itpl.iserveu.tech/passchangeisuword',
        change_password: 'https://itpl.iserveu.tech/valpassfinchangeisuverotp',
        forgotPassOTP: 'https://itpl.iserveu.tech/api/passforgsenotpuser',
        newPassOTP: 'https://itpl.iserveu.tech/api/passisuresnewpass'
    };
    public static baseUrlChangePasswordOtp = environment.userManagementApiUrl; 
    public static endPoints = {
        sendChangePasswordOtp: `${AuthApi.baseUrlChangePasswordOtp}`+'/user/send-change-password-otp',
        updatePasswordUsingOldPassword: `${AuthApi.baseUrlChangePasswordOtp}`+'/user/update-password-using-old-password'

    }
}