import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { AuthConfig } from 'src/app/app-config';
import { AuthApi } from 'src/app/auth/auth.api';

import jwt_decode from 'jwt-decode';
import * as vex from 'vex-js';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit, OnDestroy {
  ch_password: FormGroup;
  walletBalance = 0;
  wallet2Balance = 0;
  notifications = <any>[];
  allNotifications = <any>[];
  fetchingNotifs = false;
  showNotifs = false;
  userName = '';
  notiStorage: any;
  unsub = new Subject();
  shortUN: string;
  con_pass_err: boolean;
  new_pass_err: boolean;
  otp_sent: boolean=true;
  show1: any;
  showPassword1: any;
  show2: any;
  showPassword2: any;
  show3: any;
  showPassword3: any;
  flag: boolean;
  flag2: boolean;

  constructor(
    private appService: AppService,
    private ngxSpinner: NgxSpinnerService,
    private storage: StorageMap
  ) { }

  ngOnInit() { 
    this.ch_password = new FormGroup({
      old_password: new FormControl('', [Validators.required]),
      new_password: new FormControl('', [Validators.required, Validators.pattern("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=\\S+$).{6,}$")]),
      con_password: new FormControl('', [Validators.required]),
      otp: new FormControl('', null)
    });    
    const tokenData: {user_name: string} = jwt_decode(sessionStorage.getItem('access_token'));    
    this.userName = tokenData.user_name;
    if(this.userName.length > 10){
      this.shortUN = this.userName.substr(0,10) + '..';
    }else{
       this.shortUN = this.userName;
    }
    this.appService.walletBalance
    .pipe(takeUntil(this.unsub))
    .subscribe(
      val => {
        console.log('Wallet Balance in Navbar: ', val);
        this.walletBalance = val;
      }
    );

    this.appService.wallet2Balance
    .pipe(takeUntil(this.unsub))
    .subscribe(
      val => {
        console.log('Wallet 2 Balance in Navbar: ', val);
        this.wallet2Balance = val;
      }
    );

    this.loadingNotifications();


  }

  getNotifications() {

    // Call Api, only when notification pop up is opened.
    if (this.showNotifs) {
      this.fetchingNotifs = true;
      this.storage.get('notifications')
      .pipe(finalize(() => { this.fetchingNotifs = false; }))
      .subscribe((notifs: any) => {
        this.notiStorage = notifs;
        this.allNotifications = this.notifications = notifs.result;
      });
    }

  }

  filterNotifications(type: string) {
    this.notifications = this.allNotifications.filter(notif => notif.Type === type);
  }

  fetchWallet() {
    // this.ngxSpinner.show('walletSpinner', { bdColor: "rgba(0, 0, 0, 0.5)", type: "timer" });
    // this.appService.fetchWalletBalance();
  }

  fetchWallet2() {
    this.ngxSpinner.show('wallet2Spinner', { bdColor: "rgba(0, 0, 0, 0.5)", type: "timer" });
    this.appService.fetchWallet2Blanace();
  }

  logOutApp() {
    this.appService.logOut();
  }

  loadingNotifications() {
    const notiContainer = document.querySelector('.noti-body');
    // console.log('Noti Container: ', notiContainer);

    notiContainer.addEventListener('scroll', this.scrollEvent);
  }

  scrollEvent = () => {
    const notiContainer = document.querySelector('.noti-body');
    const { scrollTop, scrollHeight, clientHeight } = notiContainer;


      if ((Math.floor(scrollTop) + clientHeight) > (scrollHeight - 5)) {
        // console.log('API Called.');
        // console.log('Notifications: ', this.notiStorage);

        // Maximum 60 Notifications will be kept in the Storage. 
        if (('next' in this.notiStorage) && this.notiStorage.result.length <= 50 ) {

          notiContainer.removeEventListener('scroll', this.scrollEvent);

          const notiApiFeed = {
            "product_name":"Global",
            "user_name": this.userName,
            type: '',
            page: this.notiStorage.next.page,
            "limit":"10"    
          };
          this.fetchingNotifs = true;
          this.appService.fetchNotifications(notiApiFeed)
          .pipe(finalize(() => { this.fetchingNotifs = false; }))
          .subscribe(
            (res: any) => { 
              console.log('Scroll Notifications Res: ', res);

              const notiData = {
                ...this.notiStorage,
                ...res.data,
                result: [...this.notiStorage.result, ...res.data.result]
              }

              // console.log('Update Notification Data: ', notiData);

              this.storage.set('notifications', notiData).subscribe(() => { 
                this.notiStorage = notiData;
                this.allNotifications = this.notifications = notiData.result;
                // this.valve = true;
                notiContainer.addEventListener('scroll', this.scrollEvent);
              }); 
            },
            (err: any) => {
              console.log('Scroll Notifications Error: ', err);
            }
          );

        }

      }
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }
  change_password() {
    document.getElementById("mymodall").style.display = 'block';
    this.ch_password.reset();
    this.con_pass_err = false;
    this.new_pass_err = false;
  }
  async send_otp() {
    // this.ch_password.reset();
    this.ngxSpinner.show('Waitspinner');
    const { old_password, new_password } = this.ch_password.getRawValue();
    console.log(old_password);
    const Data = {
      newPassword: new_password,
      oldPassword: old_password
    };
    const encurldata = await AuthConfig.config.encodeUrl(AuthApi.endPoints.sendChangePasswordOtp);
    this.appService.send_otp(encurldata, Data)
      .pipe(finalize(() => { this.ngxSpinner.hide('Waitspinner'); }))
      .subscribe(
        (res: any) => {
          this.otp_sent = false;
          vex.dialog.alert(res.statusDesc);
        },
        (err: any) => {
          document.getElementById("mymodall").style.display = 'none';
          vex.dialog.alert(err.error.statusDesc);
        }
      );
  }
  async changePassword() {
    // document.getElementById("mymodall").style.display = 'none';
    this.otp_sent = true;
    this.ngxSpinner.show('Waitspinner');
    this.otp_sent = false;
    const { old_password, new_password, otp } = this.ch_password.getRawValue();
    // const { old_password, new_password, otp } = this.ch_password.getRawValue();
    console.log(old_password);
    const Data = {
      oldPassword: old_password,
      otp: otp,
      newPassword: new_password
    };

    const encurldata = await AuthConfig.config.encodeUrl(AuthApi.endPoints.updatePasswordUsingOldPassword);
    this.appService.change_password(encurldata, Data)
      .pipe(finalize(() => { this.ngxSpinner.hide('Waitspinner'); }))
      .subscribe(
        (res: any) => {
          // document.getElementById("mymodall").style.display = 'none';
          vex.dialog.alert(res.statusDesc);
        },
        (err: any) => {
          // document.getElementById("mymodall").style.display = 'none';
          vex.dialog.alert(err.error.statusDesc);
        }
      );
  }

  close_modall() {
    document.getElementById("mymodall").style.display = 'none';
    this.otp_sent = true;
  }

  Myprofile() {
    this.appService.Myprofile();
  }
  // change_password() {
  //   document.getElementById("mymodall").style.display = 'block';
  //   this.ch_password.reset();
  //   this.con_pass_err = false;
  //   this.new_pass_err = false;
  // }

  pass_show1() {
    this.show1 = !this.show1;
    this.showPassword1 = !this.showPassword1;
  }
  pass_show2() {
    this.show2 = !this.show2;
    this.showPassword2 = !this.showPassword2;
  }
  pass_show3() {
    this.show3 = !this.show3;
    this.showPassword3 = !this.showPassword3;
  }

  pass_input1(evt) {
    var new_password = this.ch_password.get('new_password').value;
    if (new_password != '' && evt == new_password) {
      this.new_pass_err = true;
      this.flag = false;
    } else {
      this.new_pass_err = false;
      this.flag = true;
    }
  }

  pass_input2(evt) {
    var old_password = this.ch_password.get('old_password').value;
    var con_password = this.ch_password.get('con_password').value;
    if (old_password != '' && evt == old_password) {
      this.new_pass_err = true;
      this.flag = false;
    } else {
      this.new_pass_err = false;
      this.flag = true;
    }
    if (con_password != null && con_password != '' && evt != con_password) {
      this.con_pass_err = true;
      this.flag2 = false;
    } else {
      this.con_pass_err = false;
      this.flag2 = true;
    }
  }
  pass_input3(evt) {
    var new_password = this.ch_password.get('new_password').value;
    if (evt != "" && evt == new_password) {
      this.con_pass_err = false;
      this.flag2 = true;
    } else {
      this.con_pass_err = true;
      this.flag2 = false;
    }
  }

}
