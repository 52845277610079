import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admi-name',
  templateUrl: './admi-name.component.html',
  styleUrls: ['./admi-name.component.scss']
})
export class AdmiNameComponent{
static obj : any;
  public static adminname(){
    var hideobj={ dmt: ["previousAmount","balanceAmount","transactionType","userName","masterName"] , comission: []  }
   return  this.obj= {demoisu:hideobj};
    
 }

}
