import { Injectable, OnInit } from '@angular/core';
import * as vex from 'vex-js';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  _featureId : string,
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
  tokenAuth?: boolean;
  hideInBreadcrumb?: boolean;
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

@Injectable()
export class NavigationItem {

  services = {
    retailer: [
      {
        id: 'Services',
        title: 'Services',
        type: 'collapse',
        icon: 'feather icon-home',
        children:[
          {
            id: 'moneytransfer',
            title: 'Money Transfer',
            type: 'item',
            url: 'v1/fundtransfer',
            _featureId: '25',
          },
          
          {
            id: 'operator',
            title: 'Recharge',
            type: 'item',
            url: 'v1/operator',
            _featureId: '4',
          },
          {
            id: 'bharatbillpay',
            title: 'Bill Pay',
            type: 'item',
            url: 'v1/bharatbillpay',
            _featureId: '52',
          },
          {
            id: 'unifiedaeps',
            title: 'Unified AEPS',
            type: 'item',
            url: 'v1/unifiedaeps',
            _featureId: '61',
          },
          {
            id: 'newmatm',
            title: 'mATM',
            type: 'item',
            url: '/v1/newmatm',
            // icon: 'feather icon-home',
            // hideInBreadcrumb: true,
            _featureId: '48',            
          },          
          {
            id: 'adhaarpay',
            title: 'Adhaar Pay',
            type: 'item',
            url: 'v1/adhaarpay',
            _featureId: '57',
          },
          {
            id: 'Insurance',
            title: 'Insurance',
            type: 'item',
            url: 'v1/insurance',
            _featureId: '65',
          },         
          // {
          //   id: 'mATM',
          //   title: 'mATM',
          //   type: 'item',
          //   url: 'v1/matmsdk',
          //   _featureId: '48',
          // },
        ]
      },
      //===============================
      // {
      //   id: 'generalReeport',
      //   title: 'General Report',
      //   icon: 'pr-transaction',
      //   type: 'item',
      //   url: 'generalreports',
      //   _featureId: 'N/A'
      // },
     //===============================
      {
        id: 'transReport',
        title: 'Transaction Report',
        type: 'collapse',
        icon: 'pr-transaction',
        children: [
          {
            id: 'dmt2',
            title: 'DMT',
            type: 'item',
            url: 'reports/dmt2',
            _featureId: '25'
          },
          {
            id: 'recharge2',
            title: 'Recharge',
            type: 'item',
            url: 'reports/recharge2',
            _featureId: '4'
          },
          {
            id: 'upi',
            title: 'UPI',
            type: 'item',
            url: 'reports/upi',
            _featureId: '53'
          },
          {
            id: 'commission',
            title: 'Commission',
            type: 'item',
            url: 'commission',
            _featureId: 'N/A'
          },
          {
            id: 'pos',
            title: 'POS',
            type: 'item',
            url: 'POS',
            _featureId: '64'
          },
          {
            id: 'wallet',
            title: 'Wallet',
            type: 'item',
            url: 'wallet',
            _featureId: 'N/A'
          },
          {
            id: 'cashout',
            title: 'Cashout',
            type: 'item',
            url: 'reports/cashout',
            _featureId: '50'
          },
          {
            id: 'AEPS',
            title: 'AEPS',
            type: 'item',
            url: 'aeps',
            _featureId: '61'
          },
          {
            id: 'mATM',
            title: 'mATM',
            type: 'item',
            url: 'matm',
            _featureId: 'N/A'
          },
          {
            id: 'BBPS',
            title: 'BBPS',
            type: 'item',
            url: 'bbps',
            _featureId: '52'
          },
          // {
          //   id: 'POS',
          //   title: 'POS',
          //   type: 'item',
          //   url: 'POS',
          //   _featureId: 'N/A'
          // },
           {
            id: 'insurance',
            title: 'Insurance',
            type: 'item',
            url: 'insurancerepprt',
            _featureId: '65'
          }
        ]
      },

      // {
      //   id: 'wallettopup',
      //   title: 'Wallet-Top-Up',
      //   icon: 'pr-wallet',
      //   type: 'collapse',
      //   children: [
      //     {
      //       id: 'wallettopuprequest',
      //       title: 'Request',
      //       type: 'item',
      //       icon: 'pr-wallet',
      //       url: 'wallettopup/wallettopuprequest',
      //       _featureId: 'N/A'
      //     },
      //     {
      //       id: 'wallettopuprequestreport',
      //       title: 'Report',
      //       type: 'item',
      //       icon: 'pr-wallet',
      //       url: 'wallettopup/wallettopuprequestreport',
      //       _featureId: 'N/A'
      //     }
      //   ]
      // },
      // {
      //   id: 'cashout',
      //   title: 'Cashout',
      //   icon: 'pr-cashout',
      //   type: 'item',
      //   url: 'wallet-cashout',
      //   _featureId: 'N/A'
      // },      
      // {
      //   id: 'talktous',
      //   title: 'Talk to Us',
      //   icon: 'pr-user-circle-o',
      //   type: 'item',
      //   url: 'talktous',
      //   _featureId: 'N/A'
      // }, 
      // {
      //   id: 'helpsupport',
      //   title: 'Help & Support',
      //   icon: 'pr-user-circle-o',
      //   type: 'item',
      //   url: 'support',
      //   _featureId: 'N/A'
      // },
       
      {
        id: 'matmdriver',
        title: 'Download mATM Driver',
        icon: 'pr-cloud-download',
        type: 'item',
        url: 'assets/matmsdkfile/ELWebService_windows-x64_1.0.7 .rar',
        _featureId: 'N/A',
        external: true
      }, 
      {
        id: 'buymatm',
        title: 'Buy mATM',
        icon: 'pr-buysellads',
        type: 'item',
        url: 'https://ecom.iserveu.tech',
        _featureId: 'N/A',
        target: '_blank',
        external: true
      }, 
         
      // {
      //   id: 'dmtsdk',
      //   title: 'DMT SDK',
      //   icon: 'pr-ticket',
      //   type: 'item',
      //   url: 'https://dmt-sdk.web.app',
      //   external: 'yes',
      //   target: '_blank',
      //   _featureId: 'N/A'
      // }
      // {
      //   id: 'demat',
      //   title: 'Open Demat Account',
      //   type: 'item',
      //   icon: 'feather icon-crosshair',
      //   url: 'https://secure.icicidirect.com/accountopening/?rmcode=ISERV343&ibempcd=', //prod
      //   target: true,
      //   external: true,
      //   tokenAuth: true,
      //   _featureId: 'N/A'
      // },
      {
        id: 'kyc',
        title: 'KYC',
        icon: 'pr-qrcode',
        type: 'item',
        // url: '/v1/Kyc',
        _featureId: 'N/A',
        status: true
      }, 

    ],
    nonRetailer: [
      {
        id: 'transReport',
        title: 'Transaction Report',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'dmt2',
            title: 'DMT2',
            type: 'item',
            url: '/v1/reports/dmt2',
            _featureId: 'N/A'
          },
          {
            id: 'commission',
            title: 'Commission',
            type: 'item',
            url: '/v1/reports/commission',
            _featureId: 'N/A'
          },
          {
            id: 'wallet',
            title: 'Wallet',
            type: 'item',
            url: '/v1/reports/wallet',
            _featureId: 'N/A'
          },
        
        ]
      }
    ]
  }

  navItems = [

    {     id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/v1/dashboard/analytics',
          icon: 'pr-dashboard',
          _featureId: 'N/A'

    },
    {
      id: 'services',
      title: 'SERVICES',
      type: 'group',
      icon: 'feather icon-layers',
      children: []
    },
    // {
    //   id: 'commission',
    //   title: 'Commission',
    //   type: 'item',
    //   icon: 'feather icon-layers',
    //   // url: 'https://partner-iserveu.web.app/authentication/auth-token/',//staging
    //   url :'https://commissionset-dashboard.web.app/authentication/auth-token/', //prod
    //   target: true,
    //   external: true,
    //   tokenAuth: true,
    //   _featureId: 'N/A'
    // }
  ];
  

  public get() {
    let userRole = '';
    try {
      const { userInfo: { userType } } = JSON.parse(sessionStorage.getItem('dashboardData'));
      userRole = userType;
      
    } catch (error) {
      window.location.reload();
    }

    // console.log(`%c ${this.userRole}`, 'color: green; font-weight: bold; font-size: 10rem;')

    this.navItems.find(item => item.id === 'services').children = (userRole === 'ROLE_RETAILER') ? <any>this.services.retailer : <any>this.services.nonRetailer;
    // this.navItems.find(item => item.id === 'commission').title = (userRole === 'ROLE_ADMIN') ? 'Plan Configuration' : 'My Plan';

    // return NavigationItems;
    return this.navItems;
  }
}
