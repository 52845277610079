import {Component, HostBinding, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { AppService } from './app.service';

import * as vex from 'vex-js';
import * as vexDialog from 'vex-dialog';
import { PushNotifyService } from './push-notify.service';
import { Socket2Service } from './socket2.service';
import { environment } from 'src/environments/environment';
import { CookieService } from "ngx-cookie-service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router, 
    private appService: AppService,
    private cookieService: CookieService 
  ) {
    // Registering Vex Dialog Plugin only once through out the project
    vex.registerPlugin(vexDialog);
    vex.defaultOptions.className = 'vex-theme-default';
    
  }
  @HostBinding('attr.app-version') appVersionAttr = environment.appVersion;
  async ngOnInit() {
    await this.appService.Gotodashboard();     
    if (sessionStorage.getItem('access_token')) { 
      this.appService.observeInternetConn(); // Observe Internet Connection
      this.appService.autoLogOut();
    } else {            
        this.appService.logOut();
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });    
  }
}
