import {Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {NavigationItem} from '../../navigation';
import {NextConfig} from 'src/app/app-config';
import {Location} from '@angular/common';
import { AppService } from 'src/app/app.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import Toastify from 'toastify-js';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss']
})
export class NavItemComponent implements OnInit, OnDestroy {
  @Input() item: NavigationItem;
  public nextConfig: any;
  public themeLayout: string;
  availableUserFeature = <any>[];
  unsub = new Subject();
  dashboardData=JSON.parse(sessionStorage.getItem('dashboardData'))
  userProfile: any = this.dashboardData.userInfo.userProfile;
  mymodal3: boolean;
  statusDesc: any;
  itemStatus: string;
  close: boolean;
  constructor(private location: Location, private _appService: AppService, private router: Router) {
    this.nextConfig = NextConfig.config;
    this.themeLayout = this.nextConfig['layout'];
  }

  ngOnInit() {
    this.checkKyc();
    this._appService.userDynamicFeature
    .pipe(takeUntil(this.unsub))
    .subscribe(
      val => {
        // console.log('User Dynamic Features: ', val);
        this.availableUserFeature = val;
      }
    );
    // this.availableUserFeature = JSON.parse(atob(sessionStorage.getItem('dynmcusrfture')));
  }

  //Kyc check
checkKyc(){
  let statusItem = this.userProfile.kycActiveStatus;
  this.statusDesc = this.userProfile.kycActiveStatus;
  let next = false;
  if(this.item['status']==true){
    switch(statusItem){
      case '10':this.itemStatus = 'APPROVED'
      break;
      case '4':this.itemStatus = 'PENDING'
      break;
      case '5':this.itemStatus = 'REJECTED'
      this.mymodal3=true;
      this.close = false;
      next = true;
      break;
      case '6':this.itemStatus = 'REUPLOAD'
      this.mymodal3=true;
      this.close = true;
      next = true;
      break;
      case '2':
      case '3':
        this.itemStatus = 'Not Started'
        this.mymodal3=true;
        this.close = false;
        next = true;
       break;
       case '0':
       case '1':
       case null:
        this.itemStatus = 'Not Started'
        this.mymodal3=true;
        this.close = true;
        next = true;
        break;       
    }
  }
}

  /* Feature Check Availability */
  featureCheck(_featureId: any){
    if(_featureId === 'N/A'){return true;}
    const _currArray = this.availableUserFeature;
    // console.log(_currArray);
    // console.log( _currArray[25].active);    
    for(let i=0;i<_currArray.length;i++){
      // console.log('current active ',_currArray[i].active);
      // console.log('current id',_currArray[i].id);    
      if(_currArray[i].id === Number(_featureId) && _currArray[i].active){
        return true;
      }
    }
    return false;
  }

  closeOtherMenu(event) {
    if (this.nextConfig['layout'] === 'vertical') {
      const ele = event.target;
      if (ele !== null && ele !== undefined) {
        const parent = ele.parentElement;
        const up_parent = parent.parentElement.parentElement;
        const last_parent = up_parent.parentElement;
        const sections = document.querySelectorAll('.pcoded-hasmenu');
        for (let i = 0; i < sections.length; i++) {
          sections[i].classList.remove('active');
          sections[i].classList.remove('pcoded-trigger');
        }

        if (parent.classList.contains('pcoded-hasmenu')) {
          parent.classList.add('pcoded-trigger');
          parent.classList.add('active');
        } else if (up_parent.classList.contains('pcoded-hasmenu')) {
          up_parent.classList.add('pcoded-trigger');
          up_parent.classList.add('active');
        } else if (last_parent.classList.contains('pcoded-hasmenu')) {
          last_parent.classList.add('pcoded-trigger');
          last_parent.classList.add('active');
        }
      }
      if ((document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open'))) {
        document.querySelector('app-navigation.pcoded-navbar').classList.remove('mob-open');
      }
    } else {
      setTimeout(() => {
        const sections = document.querySelectorAll('.pcoded-hasmenu');
        for (let i = 0; i < sections.length; i++) {
          sections[i].classList.remove('active');
          sections[i].classList.remove('pcoded-trigger');
        }

        let current_url = this.location.path();
        if (this.location['_baseHref']) {
          current_url = this.location['_baseHref'] + this.location.path();
        }
        const link = "a.nav-link[ href='" + current_url + "' ]";
        const ele = document.querySelector(link);
        if (ele !== null && ele !== undefined) {
          const parent = ele.parentElement;
          const up_parent = parent.parentElement.parentElement;
          const last_parent = up_parent.parentElement;
          if (parent.classList.contains('pcoded-hasmenu')) {
            parent.classList.add('active');
          } else if (up_parent.classList.contains('pcoded-hasmenu')) {
            up_parent.classList.add('active');
          } else if (last_parent.classList.contains('pcoded-hasmenu')) {
            last_parent.classList.add('active');
          }
        }
      }, 500);
    }
  }

  attachToken(url: string) {
    const token = sessionStorage.getItem('CORE_SESSION');
    window.open(`${url + token}`, '_blank');
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

  
  // onboarding routing
  routerLinkactive() {
    let statusItem = this.userProfile.kycActiveStatus
    switch (statusItem) {
      case '10':
        Toastify({
          text: "KYC Already activated",
          duration: 7000,
          className: 'toastr-warning',
          close: true,
          style: {
            padding: "12px 20px",
            color: "#ffffff",
            display: "inline-block",
            position: "fixed",
            transition: " all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1)",
            cursor: "pointer",
            width: "100%"
          }
        }).showToast()
        break;
      case '4':
        Toastify({
          text: "Initially inserted status will be PENDING By default",
          duration: 7000,
          className: 'toastr-warning',
          close: true,
          style: {
            padding: "12px 20px",
            color: "#ffffff",
            display: "inline-block",
            position: "fixed",
            transition: " all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1)",
            cursor: "pointer",
            width: "100%"
          }
        }).showToast()
        // Notiflix.Notify.warning("Initially inserted status will be PENDING By default")
        break;
      case '5':
        Toastify({
          text: "Onboard Status Rejected by internal",
          duration: 7000,
          className: 'toastr-warning',
          close: true,
          style: {
            padding: "12px 20px",
            color: "#ffffff",
            display: "inline-block",
            position: "fixed",
            transition: " all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1)",
            cursor: "pointer",
            width: "100%"
          }
        }).showToast()
        // Notiflix.Notify.warning("Onboard Status Rejected by internal")
        this.router.navigate(["/v1/Kyc"])
        break;
      case '2':
      case '6':
      case '3':
      case '0':
      case '1':
      case '6':
      case null:
        this.router.navigate(["/v1/Kyc"])
        break;
    }
  }
 

  startKyc(){
    this.mymodal3=false;
    this.router.navigate(["/v1/Kyc"])
  }
  close_css_modal() {
    this.mymodal3=false;
  }
  logout() {
    this._appService.logOut();
  }
}
