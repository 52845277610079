import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import jwt_decode from 'jwt-decode';
import { AppService } from "../app.service";
import { AuthApi } from "../auth/auth.api";
import { AuthConfig } from "../app-config";
import { finalize } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    allowRefresh = true;
    constructor(
        private appService: AppService,
        private http: HttpClient
    ) { }
    intercept(req: HttpRequest<HttpEvent<any>>, next: HttpHandler): Observable<any> {
        if (req.url.startsWith('https://apidev.iserveu.online')) {
            req = req.clone({
              setHeaders: {
                'Content-Type': 'application/json',
                Authorization: `${sessionStorage.getItem('access_token')}`
              }
            });
          }
        // Logout when Token Expired and User tries to send request.
        if (sessionStorage.getItem('access_token')) {
            const decToken: any = sessionStorage.getItem('exp');
            // const start_date = new Date();
            const startDate = new Date(); 
            const exp_date = new Date(startDate.getTime()+(decToken/60)*60000);
            sessionStorage.getItem('expDate')?sessionStorage.getItem('expDate'): sessionStorage.setItem('expDate', exp_date.toString());
            const expDate = new Date(sessionStorage.getItem('expDate'));
            const session = Math.ceil((<any>expDate - <any>startDate));
            const mins = Math.floor((session/1000)/60);              
            if (expDate <= startDate) {  this.appService.logOut(); }
            if (this.allowRefresh && (mins <= 10)) {
                this.refreshToken();
            }
        } else {
            this.appService.logOut();
        }
        if(req.url.includes('/oauth/token')){
        }else{
        if (!req.url.includes('getlogintoken.json')) {
            if(req.url.endsWith('isu_bank/_search') || req.url.endsWith('isu_elastic_user/_search')) {
                req = req.clone({
                    setHeaders: {
                        Authorization: `Basic ZWxhc3RpYzpUQWhJamJ4U2RzRzRRRDY3WWVmZTZQdzg=`
                    }
                });
            } else if(req.url.startsWith('https://subscribefcm-vn3k2k7q7q-uc.a.run.app/subscribetotopic')) {
                req = req.clone({
                    setHeaders: {
                        'Content-Type': `application/json`
                    }
                });
            } else {
                req = req.clone({
                    setHeaders: {
                        Authorization: `${sessionStorage.getItem('access_token')}`
                    }
                });
            }            
        }
        }
        return next.handle(req);
    }

    private async refreshToken() {//debugger;
        this.allowRefresh = false;
        let params = new URLSearchParams();   
        params.set('grant_type','refresh_token');
        params.set('refresh_token', sessionStorage.getItem('refresh_token'));
        let headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic `+ btoa('isu-client:isu-password')
        });
        let options = { headers: headers }; 
        this.http.post(environment.refreshTokenApiUrl+'/oauth/token', params.toString(), options)
        .pipe()
        .subscribe(
            (res: any) => {
                        sessionStorage.setItem('access_token', res.access_token);
                        sessionStorage.setItem('refresh_token', res.refresh_token);
                        sessionStorage.setItem('exp', res.expires_in);  
                        const s_date = new Date();
                        const exp_date = new Date(s_date.getTime()+(res.expires_in/60)*60000);
                        sessionStorage.setItem('expDate', exp_date.toString());
                        this.appService.autoLogOut(); // Refresh Logout Timer.
                    },
                    (err: any) => {
                        // console.log('Refresh Token Error: ', err);
                    }
        )
        // const encUrl = await AuthConfig.config.encodeUrl(AuthApi.url.refreshToken);
        // this.http.post(encUrl, {})
        // .pipe(finalize(() => { this.allowRefresh = true; }))
        // .subscribe(
        //     (res: any) => {
        //         // console.log('Refresh Token Response: ', res);
        //         // Replace Token
        //         sessionStorage.setItem('CORE_SESSION', res.token);
        //         this.appService.autoLogOut(); // Refresh Logout Timer.
        //     },
        //     (err: any) => {
        //         // console.log('Refresh Token Error: ', err);
        //     }
        // );

    }
}