import { Directive, HostListener, Input } from '@angular/core';
@Directive({
    selector: '[specialIsAlphaNumeric]'
})
export class SpecialCharacterDirective {

    regexStr = '^[a-zA-Z0-9_]*$';
    @Input() isAlphaNumeric: boolean;

    @HostListener('keypress', ['$event']) onKeyPress(event) {
        return new RegExp(this.regexStr).test(event.key);
    }

}